html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
    height: 100%;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
  }

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}