.confirmation-card {
  background: rgba(165, 234, 119, 0.1);
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding: 12px;
  .done-icon {
    vertical-align: middle;
    color: #59b66d;
    margin-bottom: 2px;
    margin-right: 4px;
  }
}

label,
input,
textarea {
  width: 100%;
}

label {
  display: block;
  font-size: 16px;
  margin-bottom: 5px
}

input,
textarea {
  font-size: 17px;
  border-radius: 4px;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  padding: 8px 10px;
  margin-bottom: 20px;
}

.validation-error {
  color: orangered;
  margin: -4px 0 16px;
}

.submit-button {
  width: 100%;
  font-size: 17px;
  border: 0;
  border-radius: 4px;
  background: black;
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 10px 16px;
  &:hover {
     background: rgba(black, 0.85);
     cursor: pointer;
  }
}
