.app-wrapper {
  overflow: hidden;
}

.container {
  position: relative;
  max-width: 1140px;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.narrow-container {
  position: relative;
  height: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 560px) {
    max-width: 420px;
  }
}

.masthead {
  background: radial-gradient(circle at -86px -95px, #d9dadb, #555555);
  @media screen and (min-width: 1000px) {
    min-height: 500px;
    height: 72vh;
    max-height: 700px;
  }
  .flex-container {
    @media screen and (min-width: 1000px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .jh1-wrapper {
    font-size: 0;
    @media screen and (min-width: 1000px) {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
  .jh1-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 999px) {
      max-width: 320px;
    }
    @media screen and (min-width: 1000px) {
      height: 90%;
    }
  }
  .masthead-text-wrapper {
    text-align: center;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0;
    @media screen and (min-width: 1000px) {
      margin-top: -32px;
      padding-right: 48px;
    }
    h1.my-name {
      font-size: 50px;
      transform: scaleX(0.75);
      margin: 0 0 16px;
      @media screen and (min-width: 1000px) {
        font-size: 60px;
      }
    }
    .david-text {
      color: ivory;
    }
    .section-paragraph {
      font-size: 20px;
      color: #222222;
      margin-top: 0;
      @media screen and (min-width: 1000px) {
        font-size: 23px;
      }
    }
    .contact-button {
      display: inline-flex;
      align-items: center;
      font-size: 17px;
      border: 0;
      border-radius: 4px;
      background: rgba(white, 0.5);
      color: #222222;
      text-decoration: none;
      font-weight: bold;
      transition: background-color 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      padding: 8px 16px;
      margin-top: 24px;
      &:hover {
        background: ivory;
      }
    }
    .social-links {
      display: flex;
      align-items: center;
      justify-content: center;
      .social-link {
        display: flex;
        align-items: center;
        opacity: 0.5;
        &:not(:first-child) {
          margin-left: 18px;
        }
        img {
          width: 26px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.project-section {
  text-align: center;
  overflow: hidden;
  .text-wrapper {
    max-width: 540px;
    padding: 76px 0 32px;
    margin-left: auto;
    margin-right: auto;
    .section-title {
      font-size: 36px;
      margin: 0 0 12px;
    }
    .section-paragraph {
      font-size: 19px;
      margin-top: 0;
    }
  }
  .view-project-link {
    display: inline-block;
    font-size: 20px;
    color: #a155e1;
    text-decoration: none;
    .material-icons {
      font-size: 22px;
      vertical-align: middle;
      margin-left: 6px;
      margin-bottom: 2px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
}

.pallotone-section {
  background: radial-gradient(circle at bottom, #833baf 0, #2a2d31 50%);
  color: white;
  .pallotone-logo {
    height: 34px;
    margin-bottom: 20px;
  }
}

.compound-section {
  background-image: radial-gradient(#aaaaaa 1px, transparent 0);
  background-size: 44px 44px;
  .compound-logo {
    height: 40px;
    margin-bottom: 20px;
  }
  .view-project-link {
    color: #03d395;
  }
  .compound-hero-image {
    text-align: center;
    margin-bottom: -30px;
  }
}

.contact-section {
  background: #eaeaea;
  padding-top: 64px;
  .contact-section-header {
    font-size: 36px;
    margin-bottom: 36px;
  }
  .factoids {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid #aaaaaa;
    padding: 24px 0;
    margin-top: 90px;
    .factoid {
      font-size: 17px;
      white-space: nowrap;
      padding: 16px 0;
      .material-icons {
        vertical-align: middle;
        opacity: 0.5;
        margin-right: 5px;
        margin-bottom: 2px;
      }
      @media screen and (min-width: 600px) and (max-width: 999px) {
        width: 50%;
      }
      @media screen and (max-width: 599px) {
        width: 100%;
      }
    }
    .social-links {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .social-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      opacity: 0.5;
      &:not(:first-child) {
        margin-left: 18px;
      }
      img {
        width: 26px;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
