.hero-figures {
  display: inline-block;
  font-size: 0;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  margin-bottom: 1px;
  @media screen and (max-width: 1249px) {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.imac,
.speaker {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.imac {
  width: 66vw;
  margin-left: 24px;
  margin-right: 24px;
  @media screen and (min-width: 600px) {
    width: 460px;
  }
}

.screen {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 64%;
  border: 1px solid #3f4449;
  border-radius: 3% / 5%;
  overflow: hidden;
  background: black;
  box-shadow: 0 30px 30px rgba(black, 0.4);
  .screen-inner {
    position: absolute;
    bottom: calc(15% + 12px);
    top: 12px;
    left: 12px;
    right: 12px;
    overflow: hidden;
  }
  .screen-chin {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 15%;
    background: #2a2d31;
  }
  .screen-inner-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 0 0 200px #111111;
    animation: unmask-screen 0.5s ease-in-out forwards;
    animation-delay: 0.3s;
  }
  .screenshot {
    width: 100%;
  }
  @keyframes unmask-screen {
    to {
      box-shadow: inset 0 0 0 0 #111111;
    }
  }
}

.stand-neck {
  width: 60px;
  height: 22px;
  background: #2a2d31;
  border-left: 1px solid #3f4449;
  border-right: 1px solid #3f4449;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 600px) {
    width: 72px;
    height: 26px;
  }
}

.stand-base {
  position: relative;
  width: 60px;
  height: 15px;
  background: linear-gradient(180deg, #2a2d31 0, #111111 80%);
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 600px) {
    width: 72px;
    height: 18px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: inherit;
    transform-origin: 100% 0;
    border-bottom: 1px solid #3f4449;
  }
  &:before {
    border-bottom-right-radius: 4px;
    border-right: 1px solid #3f4449;
    transform: skewX(45deg);
  }
  &:after {
    border-bottom-left-radius: 4px;
    border-left: 1px solid #3f4449;
    transform: skewX(-45deg);
  }
}

.speaker {
  position: relative;
  width: 22vw;
  background: #2a2d31;
  border: 1px solid #3f4449;
  border-radius: 8% / 6%;
  @media screen and (min-width: 600px) {
    width: 130px;
  }
  .circle {
    width: 36%;
    padding-top: 36%;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #33383d;
    background: radial-gradient(circle at center, #101011 0, #2a2d31 100%);
    margin: 12px auto;
    &.bottom-circle {
      width: 66%;
      padding-top: 66%;
    }
  }
  .mini-circle {
    width: 10%;
    padding-top: 10%;
    position: absolute;
    top: 8%;
    background: radial-gradient(circle at center, #101011 0, #2a2d31 100%);
    box-shadow: 0 0 0 2px #33383d;
    border-radius: 50%;
  }
  &.left-speaker {
    transform: perspective(500px) rotateY(20deg);
    box-shadow: -30px 30px 30px rgba(black, 0.4);
    .mini-circle {
      left: 9%;
    }
  }
  &.right-speaker {
    transform: perspective(500px) rotateY(-20deg);
    box-shadow: 30px 30px 30px rgba(black, 0.4);
    .mini-circle {
      right: 9%;
    }
  }
}
