.browser {
  display: inline-block;
  position: relative;
  width: 80vw;
  margin-left: 24px;
  margin-right: 24px;
  @media screen and (min-width: 600px) {
    width: 640px;
  }
}

.browser-window {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 62%;
  border: 1px solid #aaaaaa;
  border-radius: 12px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 30px 30px rgba(#ffffff, 0.4);
  .browser-window-inner {
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    overflow: hidden;
  }
  .browser-window-inner-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 0 0 200px #111111;
    animation: unmask-screen 0.5s ease-in-out forwards;
    animation-delay: 0.3s;
    border: 1px solid #aaaaaa;
  }
  .screenshot {
    width: 100%;
  }
  @keyframes unmask-screen {
    to {
      box-shadow: inset 0 0 0 0 #111111;
    }
  }
}
